import {getAccessToken} from '@common/token';
import {getCleanBaseUri} from '@common/baseUri';

const tokenToSessionUrl = (url: string, token?: string) => {
  return `${getCleanBaseUri()}/login/tokenToSessionAuth?access_token=${token || getAccessToken()}&redirectTo=${encodeURIComponent(
    url
  )}`;
};

export default tokenToSessionUrl;
