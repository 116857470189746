import axios from 'axios';
import getUserApiPath from '../../getUserApiPath';

interface NDAData {
	esignature?: boolean;
	firstName?: string;
	lastName?: string;
}

const generateNDA = async (data?: NDAData) => {
	const profile_url = `${getUserApiPath()}/contact/document/nda`;

	const response = await axios.post<{ esignUrl: string }>(profile_url, data);
	return response.data;
};

export default generateNDA;
