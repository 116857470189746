import React, {useCallback, useEffect, useState} from 'react';
import {WebSocketNotification} from "@hornet-api/websocket/notification";
import Stomp, {Message} from "stompjs";
import SockJS from "sockjs-client";
import {getCleanBaseUri} from "@common/baseUri";
import {getAccessToken} from "@common/token";
import isMaintenanceState from "@state/globalState/isMaintenanceState";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import useVerify2faMobileUser from "@legacy/views/auth/TwoFactorAuthView/useVerify2faMobileUser";
import useLogoutHandler from "@legacy/views/auth/useLogoutHandler";
import {getBrowserAndOS} from "@common/basic";

type Props = {
  uniqueName: string
}
const LoginSocket = ({uniqueName}: Props) => {
  const useVerify2fa = useVerify2faMobileUser()
  const logoutHandler = useLogoutHandler();
  const [client, setClient] = useState<Stomp.Client | null>(null);
  const [retryCounter, setRetryCounter] = useState<number>(0);


  const webSocketClient = () => {
    const socket = SockJS(`${getCleanBaseUri()}/stomp`);
    socket.addEventListener("close", (event) => {
      if (retryCounter < 2) {
        setTimeout(() => {
          setRetryCounter(counter => counter + 1);
        }, 2000);
      } else {
        isMaintenanceState.set(true);
      }
      console.log("=================onclose start======================")
      console.log(event);
      console.log("=================onclose end ======================")
    });
    return Stomp.over(socket);
  };

  const onMessage = useCallback((rawMessage: Message) => {
    const message = JSON.parse(rawMessage.body) as WebSocketNotification;
    switch (message.type) {
      case "LOGIN_2FA":
        const t = loadingTrigger();
        useVerify2fa({mobile2FA:{identifier: message.identifier,...getBrowserAndOS()}}).finally(() => loadingRelease(t));
        break
      case "LOGOUT_2FA":
        logoutHandler()
        break
    }

  }, []);

  /**
   * This function is not called on its topic. onMessage is the only one that works.
   */

  useEffect(() => {
    if (!uniqueName) {
      if (client?.connected) {
        client.disconnect(() => {
          console.log('Disconnected web-socket after user logout');
        });
      }
      return;
    }

    if (client?.connected) {
      return;
    }

    const thisClient = webSocketClient();
    const headersConfig = {Authorization: `Bearer ${getAccessToken()}`}
    thisClient.connect(headersConfig, () => {

      thisClient.subscribe(`/topic/messages/${uniqueName}`, onMessage, headersConfig);
      setTimeout(() => {
        setRetryCounter(0);
      }, 1000);
      setClient(thisClient);

    });
  }, [client?.connected, retryCounter, uniqueName]);

  return (<></>);
}

export default LoginSocket;
