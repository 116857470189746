import {createGlobalState} from 'react-global-hooks';
import ContactAdmin, {ContactAssociationCount} from '@interfaces/ContactAdmin';
import DocumentRequest from '@interfaces/DocumentRequest';
import InvestmentWithTransactions from '@interfaces/InvestmentWithTransactions';
import Note from '@interfaces/Note';
import ContactCompanyPosition from "@interfaces/ContactCompanyPosition";
import {SelectOptionProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import {AssociatedLoan} from "@interfaces/AssociatedLoan";
import {ILoanParticipationAgreementLight} from "@interfaces/LoanParticipationAgreement";

export const contactState = createGlobalState(null as null | ContactAdmin);
export const isLoadedCurrentTabDataState = createGlobalState<boolean>(false);
export const documentsState = createGlobalState(null as null | DocumentRequest[]);
export const investmentState = createGlobalState(null as null | InvestmentWithTransactions[]);
export const associatedLoansState = createGlobalState(null as null | AssociatedLoan[]);
export const companyPositionListState = createGlobalState<null | ContactCompanyPosition[]>(null);
export const lpaState = createGlobalState(null as null | ILoanParticipationAgreementLight[]);
export const noteState = createGlobalState(null as null | Note[]);
export const titleOfficeListState = createGlobalState<null | SelectOptionProps[]>(null);
export const contactAssociationsCountState = createGlobalState({} as ContactAssociationCount);
