import {useSetRecoilState} from "recoil";
import authenticationBlobAtom, {
  defaultAuthenticationBlob
} from "@state/recoil/authentication/core/authenticationBlobAtom";
import {useCallback} from "react";
import * as Auth from "@server-api/index";
import {removeAuthCookies} from "@server-api/index";
import {useHistory} from "react-router-dom";
import {firebaseSubscriptionRemoved,} from "@common/serviceWorker";

const useLogoutHandler = (doRedirect: boolean = true) => {
  const history = useHistory();
  const setAuthenticationBlob = useSetRecoilState(authenticationBlobAtom);

  return useCallback(async () => {
    await firebaseSubscriptionRemoved();
    await Auth.signoffUser(true);
    setAuthenticationBlob(defaultAuthenticationBlob());
    removeAuthCookies();
    if (doRedirect) {
      history.push('/');
    }
  }, [setAuthenticationBlob]);
}

export default useLogoutHandler;