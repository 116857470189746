import React, {useEffect, useState} from 'react';
import {
  checkSubscription,
  enableNotifications,
  firebaseNotifications,
  firebaseSubscriptionRemoved,
  isFirebaseCompatible
} from '@common/serviceWorker';
import {FaBell, FaBellSlash, FaCircleNotch} from 'react-icons/fa';
import classNames from 'classnames';
import {createGlobalState} from 'react-global-hooks';
import Toggle from '@components/Toggle';
import {firebaseConfigGlobalState, vapidKeyGlobalState} from "@components/FcmMessagingComponent";

export const permissionState = createGlobalState(null as null | 'default' | 'denied' | 'granted');
export const isSubscribedState = createGlobalState(false);
export const isLoadingState = createGlobalState(false);
export const isReadyState = createGlobalState(false);

type Props = {
  width?: number | string;
  className?: string;
  asLink?: boolean;
  asToggle?: boolean;
};

const PushNotificationRequest = ({
  width = '100%',
  className,
  asLink = false,
  asToggle = false,
}: Props) => {
  const [permission, setPermission] = permissionState.use();
  const [isSubscribed, setIsSubscribed] = isSubscribedState.use();
  const [isLoading, setIsLoading] = isLoadingState.use();
  const [isHovered, setIsHovered] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [disabledReason, setDisabledReason] = useState<undefined | string>(undefined);
  const [isOn, setIsOn] = useState(false);

  const vapidKey = vapidKeyGlobalState.useValue();
  const firebaseConfig = firebaseConfigGlobalState.useValue();

  const handleOnClick = async (event: React.MouseEvent<HTMLElement> | undefined) => {
    event?.preventDefault();
    setIsLoading(true);
    const permission = await Notification.requestPermission();
    setPermission(permission);
    if (vapidKey && firebaseConfig && permission === 'granted' && isFirebaseCompatible()) {
      if (await firebaseNotifications(vapidKey, firebaseConfig)) {
        await enableNotifications();
        setRefresh(refresh + 1);
      }
    }
    setIsHovered(false);
    setIsLoading(false);
  }

  const handleOnChange = async (newVal: boolean) => {
    if (disabledReason) return;
    if (isOn && !newVal) {
      // turn off
      setIsLoading(true);
      await firebaseSubscriptionRemoved();
      setIsLoading(false);
      setRefresh(refresh + 1);
    } else if (!isOn && newVal) {
      // turn on
      await handleOnClick(undefined)
    }
  }

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      checkSubscription().then(setIsSubscribed)
        .finally(() => {
          setIsLoading(false);
          if (!isLoadingState.get()) isReadyState.set(true);
        });
    })
  }, [refresh]);

  useEffect(() => setPermission(typeof Notification !== "undefined" ? Notification.permission : 'default'), []);

  useEffect(() => {
    if (!asToggle) return
    if (permission === 'denied') {
      setDisabledReason('Please enable browser notifications and reload page.');
      setIsOn(false)
    } else if (permission === 'granted') {
      console.log(isSubscribed)
      setIsOn(isSubscribed);
    } else {
      setDisabledReason(undefined)
      setIsOn(false)
    }
  }, [isSubscribed, permission, asToggle]);

  if (asToggle) {
    return (
      <Toggle
        isOn={isOn}
        isLoading={isLoading}
        disabledReason={disabledReason}
        onChange={handleOnChange}
      />
    );
  }

  if (isLoading) {
    if (asLink) {
      return (
        <div style={{width: width}}>
          <a
            className={classNames('text-secondary', className)}
            style={{opacity: 0.3}}
            href={'#'}
            onClick={(e) => e.preventDefault()}
          >
            <FaCircleNotch className={'spin'}/> Loading Notifications
          </a>
        </div>
      );
    }
    return (
      <div style={{width: width}}>
        <button
          type="button"
          className={classNames('btn btn-secondary btn-block', className)}
          disabled={true}
          style={{opacity: 0.3}}
        >
          <FaCircleNotch className={'spin'}/> Loading Notifications
        </button>
      </div>
    );
  }

  if (!isFirebaseCompatible()) {
    if (asLink) {
      return (
        <div style={{width: width}}>
          <a
            className={classNames('text-secondary', className)}
            style={{opacity: 0.3}}
            href={'#'}
            onClick={(e) => e.preventDefault()}
          >
            <FaBellSlash/> Notifications are not supported.
          </a>
        </div>
      );
    }
    return (
      <div style={{width: width}}>
        <button
          type="button"
          className={classNames('btn btn-secondary btn-block', className)}
          disabled={true}
          style={{opacity: 0.3}}
        >
          <FaBellSlash/> Notifications are not supported.
        </button>
      </div>
    );
  }

  if (permission === 'denied') {
    if (asLink) {
      return (
        <div style={{width: width}}>
          <a
            className={classNames('text-secondary', className)}
            style={{opacity: 0.3}}
            href={'#'}
            onClick={(e) => e.preventDefault()}
          >
            <FaBellSlash/> Notifications disabled
          </a>
        </div>
      );
    }
    return (
      <div style={{width: width}}>
        <button
          type="button"
          className={classNames('btn btn-secondary btn-block', className)}
          disabled={true}
          style={{opacity: 0.3}}
        >
          <FaBellSlash/> Notifications disabled
        </button>
      </div>
    );
  }

  if (permission === 'granted' && isSubscribed) {
    if (asLink) {
      return (
        <div style={{width: width}}>
          <a
            className={classNames(`${isHovered ? 'text-danger' : 'text-secondary'}`, className)}
            href={'#'}
            style={{
              opacity: isHovered ? 1 : 0.3,
              transition: 'all 0.3s ease',
            }}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            onClick={async (event) => {
              event.preventDefault();
              setIsLoading(true);
              await firebaseSubscriptionRemoved();
              setIsLoading(false);
              setRefresh(refresh + 1);
            }}
          >
            {isHovered ? (
              <>
                <FaBellSlash/> Disable notifications
              </>
            ) : (
              <>
                <FaBell/> Notifications enabled
              </>
            )}
          </a>
        </div>
      );
    }
    return (
      <div style={{width: width}}>
        <button
          type="button"
          className={classNames(
            `btn btn-block ${isHovered ? 'btn-danger' : 'btn-secondary'}`,
            className
          )}
          style={{
            opacity: isHovered ? 1 : 0.3,
            transition: 'all 0.3s ease',
          }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          onClick={async () => {
            setIsLoading(true);
            await firebaseSubscriptionRemoved();
            setIsLoading(false);
            setRefresh(refresh + 1);
          }}
        >
          {isHovered ? (
            <>
              <FaBellSlash/> Disable notifications
            </>
          ) : (
            <>
              <FaBell/> Notifications enabled
            </>
          )}
        </button>
      </div>
    );
  }

  if (asLink) {
    return (
      <div style={{width: width}}>
        <a
          className={classNames('text-primary', className)}
          href={'#'}
          onClick={handleOnClick}
        >
          <FaBell/> Enable Notifications
        </a>
      </div>
    );
  }

  return (
    <div style={{width: width}}>
      <button
        type="button"
        className={classNames('btn btn-primary btn-block', className)}
        onClick={handleOnClick}
      >
        <FaBell/> Enable Notifications
      </button>
    </div>
  );
};

export default PushNotificationRequest;
