import React, {useRef, useState} from 'react';
import profileState from '@state/globalState/profileState';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import generateNDA from '@hornet-api/documents/user/generateNDA';
import {alertApiErrors} from '@common/errors';
import {useHistory} from 'react-router-dom';
import useFromNull from '@common/useFromNull';
import {FaCircleNotch} from 'react-icons/fa';
import Loading from '@components/Loading';

type Errors = { [key: string]: string };

type Props = {
  show: boolean;
  onComplete?: () => void | Promise<void>;
  onClose?: () => void | Promise<void>;
};

const SignNdaModal = ({ show, onComplete, onClose }: Props) => {
  const [errors, setErrors] = useState(null as Errors | null);
  const profile = profileState.useValue();
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
  });

  useFromNull(() => {
    setFormData({
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
    });
  }, [profile]);

  const checkErrors = () => {
    let newErrors: Errors = {};
    if (formData.firstName.length === 0 || formData.firstName === 'New') {
      newErrors.firstName = 'Enter your first name';
    }
    if (formData.lastName.length === 0 || formData.lastName === 'User') {
      newErrors.lastName = 'Enter your last name';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return newErrors;
    } else {
      setErrors(null);
      return null;
    }
  };

  const submitNda = () => {
    if (isSubmitting) return;
    setErrors(null);
    let e = checkErrors();
    if (e) return;
    setIsSubmitting(true);
    const submitData: any = { esignature: true };
    if (formData.firstName !== profile?.firstName) submitData.firstName = formData.firstName;
    if (formData.lastName !== profile?.lastName) submitData.lastName = formData.lastName;
    //@ts-ignore
    generateNDA(submitData)
      .then((res) => {
        console.log('generateNDA', res);
        //@ts-ignore
        if (res?.esignUrl) {
          //@ts-ignore
          const url = `/e-sign/${res.esignUrl}?returnTo=${encodeURIComponent(
            window.location.pathname
          )}`;
          history.push(url);
        } else {
          throw 'Could not get esignUrl';
        }
        setIsSubmitting(false);
        if (onComplete) onComplete();
      })
      .catch((e) => {
        alertApiErrors(e);
        setIsSubmitting(false);
      });
  };

  const handleClose = async () => {
    if(isSubmitting) return;
    if (onClose) {
      await onClose();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newForm = { ...formData };
    //@ts-ignore
    newForm[name] = value;
    setFormData(newForm);
  };

  const getErrorElem = (name: string) => {
    if (errors && name in errors) {
      return <div className="text-danger text-sm-left">{errors[name]}</div>;
    }
    return null;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      onEntered={() => {
        setTimeout(() => {
          if (firstInputRef.current) firstInputRef.current?.focus();
        }, 500);
      }}
      id={'ndaModal'}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          submitNda();
        }}
      >
        <Modal.Header closeButton>Verify NDA Information</Modal.Header>
        <Modal.Body>
          <div style={{ marginBottom: 20 }}>Please verify / complete the following information</div>
          <div className="form-group">
            <label>First Name</label>
            <input
              name="firstName"
              type="text"
              className={`form-control ${errors && errors.firstName ? 'is-invalid' : ''}`}
              value={formData.firstName}
              ref={firstInputRef}
              onChange={handleChange}
            />
            {getErrorElem('firstName')}
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              name="lastName"
              type="text"
              className={`form-control ${errors && errors.lastName ? 'is-invalid' : ''}`}
              value={formData.lastName}
              onChange={handleChange}
            />
            {getErrorElem('lastName')}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type={'button'} variant={'secondary'} onClick={handleClose}>
            Cancel
          </Button>
          <Button type={'submit'} variant={'primary'} disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <FaCircleNotch className={'spin'} />
              </>
            ) : (
              'Continue'
            )}
          </Button>
        </Modal.Footer>
      </form>
      {isSubmitting ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(255, 255, 255, 0.7)',
          }}
        >
          <Loading
            text={
              <>
                <div style={{ textAlign: 'center' }}>Generating NDA</div>
                <div style={{ textAlign: 'center', fontSize: '12px' }}>This can take a while</div>
              </>
            }
          />
        </div>
      ) : null}
    </Modal>
  );
};

export default SignNdaModal;
