import React, {ChangeEvent, useCallback, useRef} from "react";
import {FileUploadProps} from "@components/forms/react-hook-form-bootstrap/interfaces";
import style from "@components/forms/react-hook-form-bootstrap/style.module.scss";
import classNames from "classnames";
import {getErrorMessage} from "@components/forms/react-hook-form-bootstrap/utils";
import {useDropzone} from "react-dropzone";
import {AiOutlineDownload} from "react-icons/ai";
import {Col, Form, Row} from "react-bootstrap";
import {Controller} from "@components/forms/react-hook-form-bootstrap/Controller";

const SHOW_MAX_FILE_NAMES = 4

export const FileUploadControl = (
  {
    name,
    id,
    rules,
    label,
    shouldUnregister,
    defaultValue = '',
    control,
    onChange,
    disabled = false,
    getDefaultFunction,
    multiple = false,
    showOnly,
    horizontalLayout,
    hideSelectedValue = false,
    acceptFileType
  }: FileUploadProps
) => {
  const dv = getDefaultFunction ? getDefaultFunction(name, defaultValue) : defaultValue;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dv}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({
                 field,
                 fieldState
               }) => {
        const isRequired = Boolean(rules?.required);
        const isInvalid = Boolean(fieldState.error);
        const fileInputRef = useRef<HTMLInputElement>(null);
        const onDrop = useCallback((acceptedFiles: File[]) => {
          if (acceptedFiles && Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
            const filesToAccept = multiple ? [...field.value, ...acceptedFiles] : acceptedFiles[0];

            if (onChange) {
              onChange(filesToAccept);
            }
            field.onChange({
              target: {name, value: filesToAccept},
              type: 'change'
            });
          }
        }, [field.value]);
        const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, disabled: disabled, accept: acceptFileType});
        const hasFieldValue = !!(multiple ? field.value?.length : field.value)
        const getDragText = () => {
          if (isDragActive) {
            return (
              <div
                className={style.dropText}
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                <div
                  className={style.blueText}
                  style={{
                    fontSize: 14,
                    lineHeight: `14px`
                  }}
                >
                  Drop It Here
                </div>
              </div>
            )
          }
          if (hasFieldValue && !hideSelectedValue) {
            return (
              <div
                className={style.dropText}
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                <div
                  className={style.blueText}
                  style={{
                    fontSize: 14,
                    lineHeight: `14px`
                  }}
                >
                  {
                    Array.isArray(field.value) ?
                      <>
                        {field.value.slice(0, SHOW_MAX_FILE_NAMES).map((fieldValue: File) => {
                          return fieldValue.name
                        }).join(', ')}
                        {field.value.length > SHOW_MAX_FILE_NAMES ? ` ... ${field.value.length - SHOW_MAX_FILE_NAMES} more` : ''}
                      </>
                      : field.value.name
                  }
                  {
                    multiple &&
                    (
                      <div>
                        <button
                          type='button'
                          className="btn btn-primary mt-3 mb-2"
                          disabled={disabled}
                          onClick={(event) => {
                            event.stopPropagation();
                            if (onChange) {
                              onChange([]);
                            }
                            field.onChange({
                              target: {name, value: []},
                              type: 'change'
                            });
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
          return (
            <div
              className={style.dropText}
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
            >
              <div
                className={style.blueText}
                style={{
                  fontSize: 14,
                  lineHeight: `14px`
                }}
              >
                {multiple ? 'Choose Files' : 'Choose a File'}
              </div>
              <div
                style={{
                  fontSize: 10,
                  lineHeight: `10px`
                }}
              >
                or
              </div>
              <div
                style={{
                  fontSize: 14,
                  lineHeight: `14px`
                }}
              >
                {multiple ? 'drag your files here' : 'drag it here'}
              </div>
            </div>
          )
        };
        const handleFileEvent = useCallback((event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.files && event.target.files.length > 0) {
            const filesArray = Array.from(event.target.files);
            const filesToAccept = multiple ? [...field.value, ...filesArray] : filesArray[0]
            if (onChange) {
              onChange(filesToAccept);
            }
            field.onChange({
              target: {name, value: filesToAccept},
              type: 'change'
            });
          }
        }, [field.value])
        return (
          <Form.Group
            as={Row}
            className={classNames(style.formInput, 'mb-3')}
            controlId={id || `form-${field.name}`}
          >
            <Form.Label column sm={horizontalLayout ? 4 : 12}>
              {label}
              {isRequired ? (
                <small className="text-warning" style={{marginLeft: 4}}>
                  (Required)
                </small>
              ) : null}
            </Form.Label>
            <Col sm={horizontalLayout ? 8 : 12}>
              {!showOnly ?
                <>
                  <div
                    className={isDragActive ? style.dropContainerDrag : style.dropContainer}
                    {...getRootProps()}
                  >
                    <div className={style.dropContent}>
                      <div className={style.dropIcon}>
                        <AiOutlineDownload/>
                      </div>
                      {getDragText()}
                    </div>
                  </div>
                  <input
                    className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                    type="file"
                    id={id || `form-${field.name}`}
                    required={isRequired}
                    name={name}
                    disabled={disabled}
                    ref={fileInputRef}
                    onChange={handleFileEvent}
                    {...getInputProps()}
                  />
                  {fieldState.error ? (
                    <Form.Control.Feedback type="invalid">
                      {getErrorMessage(fieldState.error)}
                    </Form.Control.Feedback>
                  ) : null}
                </> : null
              }
            </Col>
          </Form.Group>
        );
      }}/>
  );
};