import React, {useEffect, useState} from "react";
import {
  columnsFireBaseTwoFactorDevice,
  columnsTrustedDevice,
  RegisteredTrustedDevices
} from "@legacy/views/UserProfileLegacy/TwoFaDevice/tableData";
import BootstrapSmartTable from "@components/BootstrapSmartTable";
import {Row, Col} from "react-bootstrap";
import getTwoFaRegisteredAndTrustedDevices
  from "@hornet-api/twoFactorAuth/user/mobile/getTwoFaRegisteredAndTrustedDevices";
import {alertApiErrors} from "@common/errors";


const TwoFaDevices = () => {
  const [registeredTrustedDevices, setRegisteredTrustedDevices] = useState<RegisteredTrustedDevices>();
  const loadDevicesData = () => {
    getTwoFaRegisteredAndTrustedDevices()
      .then(setRegisteredTrustedDevices)
      .catch(alertApiErrors);
  };
  useEffect(() => loadDevicesData(), []);


  return (
    <Row>
      <Col>
        <label>Registered Mobile Devices</label>
        <BootstrapSmartTable
          name="Registered Mobile Devices"
          columns={columnsFireBaseTwoFactorDevice(loadDevicesData)}
          data={registeredTrustedDevices?.twoFaMobileDevices || []}
          persistFilters={false}
          showTopToolbar={false}
        />
      </Col>
      <Col>
        <label>Trusted Devices</label>
        <BootstrapSmartTable
          name="Trusted Devices"
          columns={columnsTrustedDevice(loadDevicesData)}
          data={registeredTrustedDevices?.trustedDevices || []}
          persistFilters={false}
          showTopToolbar={false}
        />
      </Col>
    </Row>
  )
}


export default TwoFaDevices;