import React, {CSSProperties, useEffect, useMemo, useRef, useState} from "react";
import style from "./smart-toggle-switch.module.scss";

export interface ToggleOption<T> {
  value: T;
  label: string;
}

interface SwitchOption<T> {
  option: ToggleOption<T>;
  children: React.ReactNode;
  onClick: () => void;
  className: string;
  setOffsetWidth: (offsetWidth: number) => void;
}

interface SmartToggleSwitchProps<T> {
  styleCss?: CSSProperties | undefined;
  options: ToggleOption<T>[];
  value: T;
  onChange: (value: T) => void;
  disabled?: boolean;
}

const SwitchOption = <T, >({children, onClick, className, setOffsetWidth}: SwitchOption<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current?.offsetWidth) {
      setOffsetWidth(ref.current.offsetWidth)
    }
  }, [ref.current?.offsetWidth]);

  return (
    <div
      ref={ref}
      className={className}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

const getLeftPosition = (offsetWidthArray: number[], index: number, selectedIndex: number, initialLeftPosition: number): number => {
  if (index >= selectedIndex) {
    return initialLeftPosition;
  }
  return getLeftPosition(offsetWidthArray, index + 1, selectedIndex, initialLeftPosition + offsetWidthArray[index]);
}

const SmartToggleSwitch = <T, >({options, value, onChange, disabled}: SmartToggleSwitchProps<T>) => {
  const [offsetWidthArray, setOffsetWidthArray] = useState(Array.from({length: options.length}) as number[]);

  const selectedOptionIndex = useMemo(() => {
    return options.findIndex((option) => option.value === value)
  }, [options, value])

  const selectorWidth = useMemo(() => {
    return offsetWidthArray[selectedOptionIndex];
  }, [offsetWidthArray, selectedOptionIndex]);

  const selectorLeft = useMemo(() => (
    getLeftPosition(offsetWidthArray, 0, selectedOptionIndex, 0)
  ), [offsetWidthArray, selectedOptionIndex])

  return (
    <div className={`${style.spiderToggleSwitch} ${disabled ? style.disabled : ''}`}>
      <div
        className={`${style.spiderSelector} ${disabled ? style.disabled : ''}`}
        style={{
          width: selectorWidth,
          left: selectorLeft,
        }}
      ></div>
      {options.map((option, index) => (
        <SwitchOption
          key={index}
          option={option}
          className={`${style.spiderOption} ${selectedOptionIndex === index ? style.active : ""} ${disabled ? style.disabled : ''}`}
          onClick={() => onChange(option.value)}
          setOffsetWidth={(offsetWidth) => setOffsetWidthArray(prevState => {
            const newArray = [...prevState];
            newArray[index] = offsetWidth;
            return newArray;
          })}
        >
          {option.label}
        </SwitchOption>
      ))}
    </div>
  );
};

export default SmartToggleSwitch;
