import React, {useEffect, useMemo, useState} from "react";
import {
  openTwoFactorAuthenticationModal,
  twoFactorAuthState
} from "@legacy/views/UserProfileLegacy/TwoFactorAuthenticationModal";
import {profileState} from "@legacy/views/UserProfileLegacy/globalState";
import FontAwesomeIcon from "@admin/components/icons/FontAwesomeIcon";
import {fetchUser2FA} from "@hornet-api/twoFactorAuth/user/fetchUser2FA";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import Button from "react-bootstrap/Button";
import disable2FA from "@hornet-api/twoFactorAuth/user/disable2FA";
import {alertApiErrors} from "@common/errors";
import SmartToggleSwitch, {ToggleOption} from "@components/SmartToggleSwitch/SmartToggleSwitch";

import TwoFaDevices from "@legacy/views/UserProfileLegacy/TwoFaDevice/TwoFaDevices";
import {TwoFaTypeEnum} from "@interfaces/GeneratedEnums";
import isFeatureEnabledSelector from "@state/globalState/isFeatureEnabledSelector";

type Props = {
  classFieldName: string;
  classFieldValue: string;
};
const options: ToggleOption<keyof typeof TwoFaTypeEnum>[] = [
  {value: "OFF", label: "Turn off"},
  {value: "SMS", label: "Text SMS"},
  {value: "APP_PUSH_NOTIFICATION", label: "Mobile Application"},
];

const SecuritySettingsSection = ({classFieldName, classFieldValue}: Props) => {
  const profile = profileState.useValue();
  const [user2FA, setUser2FA] = twoFactorAuthState.use();
  const [user2FAType, setUser2FAType] = useState<keyof typeof TwoFaTypeEnum>("OFF");
  const isMobileApp2FaFeatureEnabled = isFeatureEnabledSelector('MOBILE_APPLICATION_2FA_FEATURE')

  const fetch2FA = () => {
    const t = loadingTrigger();
    fetchUser2FA()
      .then(setUser2FA)
      .finally(() => loadingRelease(t));
  }

  const filteredOptions = useMemo(() => {
    if (isMobileApp2FaFeatureEnabled) {
      return options;
    }
    return options.filter(({value}) => value !== "APP_PUSH_NOTIFICATION");
  }, [isMobileApp2FaFeatureEnabled])

  useEffect(() => {
    if (user2FA?.isEnabled) {
      setUser2FAType(user2FA.isFirebaseEnabled ? "APP_PUSH_NOTIFICATION" : "SMS");
    } else {
      setUser2FAType("OFF");
    }
  }, [user2FA])

  useEffect(fetch2FA, [])

  const handle2FAChange = (newVal: string) => {
    if (disabledReason) {
      alert(disabledReason);
      return
    }
    if (user2FAType === newVal)
      return;
    if (newVal === "SMS") {
      // if user tries to enable it, start the process of setting it up
      openTwoFactorAuthenticationModal();
    } else if (newVal === 'APP_PUSH_NOTIFICATION') {
      alert("Please install the Hornet Mobile Application and enable it from there.")
    } else {
      if (user2FA?.isEnabled && !user2FA?.isTurnedOffByAdmin) {
        alert("Two Factor Authentication can't be disabled");
        return
      }
      const t = loadingTrigger();
      disable2FA()
        .then(fetch2FA)
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  }

  const disabledReason = useMemo(() => {
    if (!profile || !user2FA) return 'Loading...';
    if (profile.locked) return 'This Profile is locked for Modification.';
    if (!profile.hasUser) return 'This contact does not have a user.';
    return ''
  }, [profile, profile?.locked, profile?.hasUser, user2FA, user2FA?.isTurnedOffByAdmin]);

  return (<>
    <h3>Security</h3>
    <div className="form-group row">
      <label className={classFieldName}>
        Two Factor Authentication
        {
          !!user2FA?.isEnabled && user2FA.phoneNumber &&
            <i><small>{` ${user2FA.phoneNumber}`}</small></i>
        }
      </label>
      <div className={classFieldValue}>
        <SmartToggleSwitch
          options={filteredOptions}
          value={user2FAType}
          onChange={handle2FAChange}
          disabled={profile?.locked}
        />
        {profile && profile.locked && <FontAwesomeIcon className='ml-2' icon='lock'/>}
        {
          user2FAType === 'SMS' && profile && (
            <div>
              <Button
                variant={'success'} size={'sm'} className={'mt-2'}
                onClick={openTwoFactorAuthenticationModal}
                disabled={profile.locked}
              >
                Update 2FA Phone number
                {profile.locked && <FontAwesomeIcon className='ml-2 mt-2' icon='lock'/>}
              </Button>
            </div>
          )}
      </div>
    </div>
    {
      user2FA && user2FAType === 'APP_PUSH_NOTIFICATION' && profile &&
        <TwoFaDevices/>
    }
  </>);
}

export default SecuritySettingsSection;