import axios from "axios";
import getMetaApiPath from "@hornet-api/getMetaApiPath";

export type Mobile2FA = {
  otp: string
  uniqueName:string
}
const getMobileTokenForWeb = async () => {
  const url = `${getMetaApiPath()}/2fa/get-mobile-token-for-web`;
  return axios.get<Mobile2FA>(url).then(res => res.data);
};

export default getMobileTokenForWeb;
