import Contact from '@interfaces/Contact';
import moment from "moment";
import "moment-timezone"
import React from 'react';
import {DateFormats} from "@interfaces/DateFormats";
import timeZoneState from "@state/globalState/timeZoneState";
import {addAlert} from "@components/Alert";

export const objCopy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const sortByKey = <T>(list: T[], key: keyof T) => {
  return list.sort(getComparatorByKey(key))
}

export const sortByKeyIgnoreCase = <T>(list: T[], key: keyof T) => {
  return list.sort(getComparatorByKey(key, true))
}

/**
 * Copies the input text to the clipboard.
 *
 * @param text - The text to be copied.
 *
 */
export const copyTextToClipboard = async (text: string | null) => {
  if (!text) return;
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

/**
 * Sort an array of object by any given key
 *
 * If the value of given key is a "string" type, it would change it to lower case
 *
 * @param key
 * @param lowerCase
 */
export const getComparatorByKey = <T extends { [p in keyof T]: unknown }>(key: keyof T, lowerCase: boolean = true) => {
  const getValue = (obj: T, doLowerCase: boolean) => {
    return doLowerCase ? (obj[key] as string)?.toUpperCase() : obj[key]
  }

  return (a: T, b: T) => {
    const doLowerCase = lowerCase && typeof a[key] === 'string'

    const aVal = getValue(a, doLowerCase);
    const bVal = getValue(b, doLowerCase);

    return aVal > bVal ? 1 : (aVal < bVal ? -1 : 0);
  };
}

export const stringToDate = (value: moment.MomentInput): Date | null => {
  if (!value) return null;
  return moment(value).toDate()
}

/**
 * Converts the value to a given format
 *
 * @param value
 * @param format
 */
export const formatDate = (
  value: moment.MomentInput,
  format: DateFormats = DateFormats.DATE
) => (
  value ? moment(value).format(format) : ''
);

/**
 * Converts Date object to string in a format with/without a timezone
 *
 * - No specific timezone set (in timeZoneState) - means we have to show and save dates based on User's location
 * - Without a timezone - so that the given date-time is considered to be in the server's timezone
 * - Should only be used when sending date from browser to server
 * - When skipTime is true it would not consider user's timezone and server will save it with time as 00:00:00
 *
 * @param date to be converted
 * @param skipTime skip the time/timezone part
 */
export const formatDateForServer = (
  date: moment.MomentInput,
  skipTime: boolean = false
) => {
  if (!date) {
    return '';
  }
  // No timeZone or blank string means User Specific
  if (!timeZoneState.get() && !skipTime) {
    // Converts user date in GMT and sends with a 'Z'
    return moment(date).toDate().toJSON();
  }
  return formatDate(date, DateFormats[skipTime ? 'SERVER_DATE_ONLY_FORMAT' : 'SERVER_DATETIME_FORMAT']) || '';
}

export const formatDecimals = (value: string | number | null, decimals: number = 2) => value === null ? '' : Number(value).toFixed(decimals);

export const percentage = (value: string | number | null, decimals: number = 2) => {
  const formatted = formatDecimals(value, decimals);
  if (!formatted) return formatted;
  return `${formatted}%`
}

export const NA = 'Not Set';
export const currency = (val: string | number | undefined | null, defaultValue: string = NA) => {
  if (typeof val === 'string') {
    val = val.replace(/,/g, '');
  }

  if ((typeof val === 'string' || typeof val === 'number') && !isNaN(+val)) {
    return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Number(val));
  }
  return defaultValue;
};

export const nextFrame = () => {
  return new Promise<void>((resolve) => {
    window.requestAnimationFrame(() => {
      resolve();
    });
  });
};

export const delay = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  })
}

export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

export const updateParams = (params: { [key: string]: string }) => {
  let queryString = '?';
  let i = 0;
  for (let key in params) {
    if (i > 0) queryString += '&';
    queryString += `${key}=${params[key]}`;
    i++;
  }
  if (queryString === '?') queryString = '';
  if (window.history.pushState) {
    let url = window.location.origin + window.location.pathname + queryString;
    window.history.pushState({path: url}, '', url);
  } else {
    console.error('no window.history.pushState');
  }
}

export const arrayMove = <T>(arr: T[], old_index: number, new_index: number): T[] => {
  const newArr = [...arr];
  if (new_index >= newArr.length) {
    let k = new_index - newArr.length + 1;
    while (k--) {
      //@ts-ignore
      newArr.push(undefined);
    }
  }
  newArr.splice(new_index, 0, newArr.splice(old_index, 1)[0]);
  return newArr;
};

export const valueMap = (x: number, in_min: number, in_max: number, out_min: number, out_max: number) => {
  return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export const isProfileComplete = (profile: Contact): boolean => {
  try {
    if (!profile.hasSsn || !profile.ssn) {
      return false;
    }

    if (!profile.addresses || profile.addresses.length === 0) {
      return false;
    }

    if (!profile.phoneNumbers || profile.phoneNumbers.length === 0) {
      return false;
    }
  } catch (e) {
    console.log('Error:', e);
    return false;
  }
  return true;
}

export const range = (num: number) => {
  let arr: number[] = [];
  for (let i = 0; i < num; i++) {
    arr.push(i);
  }
  return arr;
}

export const getRangeLookup = (start: number, end: number) => {
  if (start > end) {
    const tmp = start;
    start = end;
    end = tmp;
  }

  const lookup: { [p: string]: string } = {};
  for (; start <= end; start++) {
    lookup[start] = `${start}`;
  }

  return lookup;
}

export const getFullName = (contact?: Contact | null) => {
  let str = contact?.firstName;
  if (contact?.middleName) {
    str += ` ${contact.middleName}`;
  }
  str += ` ${contact?.lastName}`;
  return str;
}

export const getFullNameLastFirst = (contact?: Contact | null) => {
  let str = contact?.lastName;
  str += `, ${contact?.firstName}`;
  if (contact?.middleName) {
    str += ` ${contact.middleName}`;
  }
  return str;
}


export const mergeRefs = <T>(...refs: (React.RefObject<T> | React.ForwardedRef<T>)[]) => {
  return (node: T) => {
    // noinspection JSConstantReassignment
    for (const ref of refs) {
      //@ts-ignore
      // noinspection JSConstantReassignment
      if (ref) ref.current = node
    }
  }
}

// disables scrolling on numbers
export const disableNumberScroll = () => {
  //@ts-ignore
  if (!window.scrollNumberAdded) {
    document.addEventListener("wheel", function (event) {
      try {
        //@ts-ignore
        if (document.activeElement.type === "number") {
          //@ts-ignore
          document.activeElement.blur();
        }
      } catch (e) {
        // do nothing
      }
    });
    //@ts-ignore
    window.scrollNumberAdded = true;
  }
}

export const enumKeys = <T>(obj: T) => {
  if (!obj) return [];
  return Object.keys(obj) as (keyof T)[];
};

/**
 * Omit a specific property from an object and return a new object without that property.
 * @param obj - The original object from which a property will be omitted.
 * @param key - The property key to be omitted from the object.
 * @returns A new object without the specified property.
 */
export const omit = <T, K extends keyof T>(obj: T, key: K) => {
  const {[key]: T, ...rest} = obj
  return rest;
};

// function to check is an email is valid
export const isEmailValid = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

/**
 * Returns readable string from list
 *
 * <p>
 *   Joins all the elements with comma, except the last one.
 *   And adds "and" between the last two items.
 *   Nothing is added if it has only one item.
 *   Returns a blank string if there is nothing in the list.
 * </p>
 *
 * @param list
 */
export const prettyList = <T>(list: T[]) => (
  list.length > 1
    ? `${list.slice(0, -1).join(', ')} and ${list.slice(-1)}`
    : {0: '', 1: list[0]}[list.length]
);

export const stringToTimeAgo = (dateInString: string): string => {
  const date = stringToDate(dateInString);
  return date ? timeAgo(date) : '';
}
export const timeAgo = (date: Date): string => {
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks > 0) {
    return weeks === 1 ? "a week ago" : `${weeks} weeks ago`;
  } else if (days > 0) {
    return days === 1 ? "a day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "an hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
  } else {
    return "just now";
  }
}
export const copyToClipboard = (textToCopy: string) => {
  navigator.clipboard.writeText(textToCopy)
    .then(() => {
      addAlert({
        type: "success",
        content: 'Link copied to clipboard!'
      })
    })
    .catch((error) => {
      console.error('Unable to copy link:', error);
      addAlert({
        type: "danger",
        content: 'Failed to copy link to clipboard.'
      })
    });
};
export const hasValidValue = (value: any): boolean => {
  if (typeof value === 'number') {
    return true
  }
  return value && (!Array.isArray(value) || value.length) && (typeof value !== 'object' || !('value' in value) || typeof value.value === 'number' || value.value);
}

export const getBrowserAndOS = () => {
  const userAgent = navigator.userAgent;

  const browserName = detectBrowser(userAgent);
  const osName = detectOS(userAgent);

  return {browserName, osName};
}

const detectBrowser = (userAgent: string) => {
  if (userAgent.indexOf("Firefox") > -1) {
    return "Mozilla Firefox";
  } else if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1) {
    return "Google Chrome";
  } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
    return "Apple Safari";
  } else if (userAgent.indexOf("Edg") > -1) {
    return "Microsoft Edge";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    return "Opera";
  } else {
    return "Unknown Browser";
  }
}

const detectOS = (userAgent: string) => {
  if (userAgent.indexOf("Win") > -1) {
    return "Windows";
  } else if (userAgent.indexOf("Mac") > -1) {
    return "MacOS";
  } else if (userAgent.indexOf("Linux") > -1) {
    return "Linux";
  } else if (userAgent.indexOf("Android") > -1) {
    return "Android";
  } else if (userAgent.indexOf("like Mac") > -1) {
    return "iOS";
  } else {
    return "Unknown OS";
  }
}

