import {Button, Card} from "react-bootstrap";
import {openAddEditTaxRecordModal} from "@admin-ui/pages/PropertyPage/show/AddEditTaxRecordModal";
import {FaPlus} from "react-icons/fa";
import BootstrapSmartTable from "@components/BootstrapSmartTable";
import {getTaxRecordColumns, taxRecordNotesColumns} from "@admin-ui/pages/PropertyPage/show/tableData";
import React, {useEffect, useMemo, useState} from "react";
import {TaxRecordServer} from "@interfaces/TaxRecord";
import {addAlert} from "@components/Alert";
import {fetchTaxRecordsByPropertyId} from "@hornet-api/properties/taxRecords";

type Props = {
  propertyId: number
}
export const PropertyTaxes = ({propertyId}: Props) => {
  const [taxRecords, setTaxRecords] = useState<TaxRecordServer[]>();

  useEffect(() => {
    fetchTaxRecordsByPropertyId(propertyId)
      .then(setTaxRecords)
  }, [propertyId]);
  const exitingRecordYears = useMemo(() => {
    return (taxRecords || []).map((tr) => tr.year!);
  }, [taxRecords]);

  const refreshTaxRecordList = (action: 'SAVE' | 'UPDATE' | 'DELETE', taxServer: TaxRecordServer) => {
    const taxRecordsLocal = taxRecords || [];
    switch (action) {
      case "SAVE":
        setTaxRecords([...taxRecordsLocal, taxServer]);
        addAlert({
          type: "success",
          content: "Tax Record saved successfully."
        });
        break;
      case "UPDATE":
        setTaxRecords(taxRecordsLocal.map((tax) => tax.id === taxServer.id ? taxServer : tax));
        addAlert({
          type: "success",
          content: "Tax Record update successfully."
        });
        break;
      case "DELETE":
        setTaxRecords(taxRecordsLocal.filter((tax) => tax.id !== taxServer.id));
        addAlert({
          type: "success",
          content: "Tax Record delete successfully."
        });
        break;
    }
  };


  const taxRecordColumns = useMemo(() => {
    return getTaxRecordColumns(propertyId, exitingRecordYears, refreshTaxRecordList);
  }, [taxRecords, exitingRecordYears]);

  return (<Card>
    <Card.Header>Property Taxes
      <Button
        variant={"success"}
        className="pull-right"
        onClick={() => {
          openAddEditTaxRecordModal({
            propertyId: propertyId,
            exitingRecordYears: exitingRecordYears,
            onComplete: refreshTaxRecordList
          })
        }}>
        <FaPlus/> Add Tax Record
      </Button>
    </Card.Header>
    {
      taxRecords &&
        <Card.Body>
            <BootstrapSmartTable
                name="propertyTaxRecords"
                columns={taxRecordColumns}
                data={taxRecords}
                showFilter={false}
                showTopToolbar={false}
            />
            <h5 className={'mt-4'}>Notes</h5>
            <BootstrapSmartTable
                name="propertyTaxRecordsNotes"
                columns={taxRecordNotesColumns}
                data={taxRecords}
                showFilter={false}
                showTopToolbar={false}
            />
        </Card.Body>
    }
  </Card>)
}