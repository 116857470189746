/* eslint-disable no-magic-numbers */
import axios from 'axios';
import cookies from 'js-cookie';

const AUTH_COOKIE = 'HCPAT';
const RESTORE_AUTH_COOKIE = 'HCPRSAT';

export const AUTH_CODES = {
	MISSING_USERNAME_OR_PASSWORD: 'MISSING_USERNAME_OR_PASSWORD',
	INVALID_USERNAME_OR_PASSWORD: 'INVALID_USERNAME_OR_PASSWORD',
	ACCOUNT_EXPIRED: 'ACCOUNT_EXPIRED',
	PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
	ACCOUNT_DISABLED: 'ACCOUNT_DISABLED',
	EMAIL_VERIFICATION_REQUIRED: 'EMAIL_VERIFICATION_REQUIRED',
	ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
	SESSIONS_EXCEEDED: 'SESSIONS_EXCEEDED',
	BRUTE_FORCE_LOCKOUT: 'BRUTE_FORCE_LOCKOUT',
	UNKNOWN_ERROR: 'UNKNOWN_ERROR',
	INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
	PERMISSION_DENIED: 'PERMISSION_DENIED',
	NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
};

const validateStatus = (status) => {
	return (status >= 200 && status < 300) || (status >= 400 && status < 500);
};

const getAndDecodeCookie = (name) => {
	try {
		const cookie = cookies.get(name);
		if (!cookie) return null;
		const decoded = Buffer.from(cookie, 'base64').toString('utf-8');
		return JSON.parse(decoded);
	} catch (oe) {
		return null;
	}
};

const PATHS = {
	AUTHENTICATE: 'api/v1/auth/authenticate',
	AUTHENTICATE_2FA: 'api/v1/auth/verify2fa',
	AUTHENTICATE_MOBILE_APP_2FA: 'api/v1/auth/verifyMobileApp2fa',
	SIGNOUT: '/api/v1/auth/logoff',
	ACTAS: '/api/v1/auth/exchange',
	REFRESH: '/api/v1/auth/refresh',
	REVERT: '/api/v1/auth/revert',
};

let baseURL = '/';

const refreshToken = async (token) => {
	if (!token) return Promise.reject(new Error(AUTH_CODES.NOT_AUTHENTICATED));
	try {
		const { status, data } = await axios.post(
			PATHS.REFRESH,
			'',
			{baseURL, validateStatus, headers: {Authorization: `Bearer ${token}`}}
		);

		if (status >= 200 && status < 300) return data.data;
	} catch {
		// no nothing
	}
	return Promise.reject(new Error(AUTH_CODES.NOT_AUTHENTICATED));
};

export const initialize = ({
	authenticationUri = '/'
}) => {
	baseURL = authenticationUri;
};

export const getUser = async () => {
	let activeUser = getAndDecodeCookie(AUTH_COOKIE);
	if (!activeUser) return [];

	if (activeUser.exp <= (Date.now() / 1000).toFixed(0))
		activeUser = await refreshToken(activeUser.token);

	if (!activeUser || activeUser.exp <= (Date.now() / 1000).toFixed(0))
		return [];

	const users = [activeUser];

	const restoreUser = getAndDecodeCookie(RESTORE_AUTH_COOKIE);

	if (restoreUser) users.push(restoreUser);

	return users;
};

export const removeAuthCookies = () => {
	cookies.remove(AUTH_COOKIE);
	cookies.remove(RESTORE_AUTH_COOKIE);
};

export const signoffUser = async (includeAdmin = true) => {
	const users = await getUser();
	const u = (users ?? []).shift();
	if (!u) return;
	const impersonating = users.length > 0;
	const signoutPath = impersonating ? PATHS.REVERT : PATHS.SIGNOUT;

	try {
		const { status, data } = await axios.post(
			signoutPath,
			'',
			{ baseURL, validateStatus, headers: { Authorization: `Bearer ${u.token}`} }
		);

		if (status >= 200 && status < 300 && impersonating) return data ? [data] : [];

	} catch (oe) {
		// do nothing
	}

	if (includeAdmin) {
		const a = (users || []).shift();
		if (!a) return;
		try {
			await axios.post(
				PATHS.SIGNOUT,
				'',
				{ baseURL, validateStatus, headers: { Authorization: `Bearer ${a.token}`} }
			);
		} catch (oe) {
			// do nothing
		}
	}
	return [];
};

export const signinUser = async (username, password) => {
	await signoffUser(true);
	try {
		const {status, data} = await axios.post(
			PATHS.AUTHENTICATE,
			{username, password},
			{baseURL, validateStatus,withCredentials: true}
		);

		if (status >= 200 && status < 300) return [data.data];

		if (data && data.error) return Promise.reject(new Error(data.error));
	} catch (oe) {
		// do nothing
		return Promise.reject(new Error(JSON.stringify(oe)));
	}
	return Promise.reject(new Error(AUTH_CODES.UNKNOWN_ERROR));
};

export const verify2faUser = async (otp2fa) => {
	const users = await getUser();
	const user = users[0];
	if (!user) return Promise.reject(new Error(AUTH_CODES.NOT_AUTHENTICATED));
	const {status, data} = await axios.post(
		PATHS.AUTHENTICATE_2FA,
		{otp2fa},
		{baseURL, validateStatus, headers: {Authorization: `Bearer ${user.token}`}}
	);

	if (status >= 200 && status < 300) return data.data ? [data.data] : data;

	if (data && data.error) return Promise.reject(new Error(data.error));

	if (data) {
		return Promise.reject({response: {status, data}});
	}
	return Promise.reject(new Error(AUTH_CODES.UNKNOWN_ERROR));
};

export const actAsUser = async (username) => {
	const users = await getUser();
	const user = [...(users || [])].shift();
	if (!user || !user.roles) return Promise.reject(new Error(AUTH_CODES.NOT_AUTHENTICATED));
	if (!user.roles.includes('ROLE_SWITCH_USER')) return Promise.reject(new Error(AUTH_CODES.PERMISSION_DENIED));
	try {
		const {status, data} = await axios.post(
			PATHS.ACTAS,
			{sub: username},
			{baseURL, validateStatus, headers: {Authorization: `Bearer ${user.token}`}}
		);

		if (status >= 200 && status < 300) return [data.data, ...users];

		if (data && data.error) return Promise.reject(new Error(data.error));

	} catch (oe) {
		// do nothing
	}

	return Promise.reject(new Error(AUTH_CODES.UNKNOWN_ERROR));
};

export const actAsUserWithToken = async (username, token, adminUser, adminRoles, isContact) => {
	const users = await getUser();
	try {
		const {status, data} = await axios.post(
			PATHS.ACTAS,
			{sub: username, adminUser: adminUser, adminRoles: adminRoles, isContact},
			{baseURL, validateStatus, headers: {Authorization: `Bearer ${token}`}}
		);
		console.log('status', status);
		console.log('data', data);
		console.log('token', token);
		console.log('adminUser', adminUser);
		console.log('adminRoles', adminRoles);

		if (status >= 200 && status < 300) return [data.data, ...users];

		if (data && data.error) return Promise.reject(new Error(data.error));

	} catch (oe) {
		// do nothing
	}

	return Promise.reject(new Error(AUTH_CODES.UNKNOWN_ERROR));
};

export const refreshUserToken = async () => {
	const users = await getUser();
	const user = users[0];
	if (!user) return Promise.reject(new Error(AUTH_CODES.NOT_AUTHENTICATED));
	try {
		const refresh = await refreshToken(user.token);
		return [
			refresh,
			...users.slice(1),
		];
	} catch (oe) {
		return Promise.reject(oe);
	}
};

export const verifyMobile2faUser = async (otp2fa) => {
	const users = await getUser();
	const user = users[0];
	if (!user) return Promise.reject(new Error(AUTH_CODES.NOT_AUTHENTICATED));
	const {status, data} = await axios.post(
		PATHS.AUTHENTICATE_MOBILE_APP_2FA,
		otp2fa,
		{baseURL, validateStatus, headers: {Authorization: `Bearer ${user.token}`},withCredentials: true}
	);

	if (status >= 200 && status < 300) return data.data ? [data.data] : data;

	if (data && data.error) return Promise.reject(new Error(data.error));

	if (data) {
		return Promise.reject({response: {status, data}});
	}
	return Promise.reject(new Error(AUTH_CODES.UNKNOWN_ERROR));
};

