/* eslint-disable no-magic-numbers */
import axios from 'axios';
import getUserApiPath from '../getUserApiPath';

const getFinancialsReportAvailableNoRedirect = async () => {
  const fund_url = `${getUserApiPath()}/contact/fund/currentFinancials/check/no-redirect`;
  const response = await axios.get<{ available: boolean }>(fund_url);
  return response.data.available;
};

export default getFinancialsReportAvailableNoRedirect;
