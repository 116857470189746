import {formatDate} from "@common/basic";
import {TaxRecordServer} from "@interfaces/TaxRecord";
import {Column} from "@components/BootstrapSmartTable";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {FaDownload, FaPencilAlt, FaRegEye, FaTrash} from "react-icons/fa";
import {openAddEditTaxRecordModal} from "@admin-ui/pages/PropertyPage/show/AddEditTaxRecordModal";
import {deleteTaxRecord} from "@hornet-api/properties/taxRecords";
import {alertApiErrors} from "@common/errors";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {DocumentObject} from "@interfaces/DocumentRequest";
import {BiLogInCircle, BiLogOutCircle} from "react-icons/bi";
import {downloadDocumentUrl, viewDocumentUrl} from "@hornet-api/contact/document/documentRequestAction";
import {getAccessToken} from "@common/token";
import openUrlWithToken from "@hornet-api/openUrlWithToken";
import {openShowDocumentModal} from "@components/ShowDocumentModal";
import {downloadFileByUrl} from "@common/utils/fileDownloader";
import {deletePropertyDocument, toggleDocumentVisibility, updateFreeFormTitle} from "@hornet-api/properties/document";
import {addAlert} from "@components/Alert";
import fileNameCheckPrompt from "@hornet-api/documents/fileNameCheckPrompt";
import updateDocumentName from "@hornet-api/documents/updateDocumentName";
import {PublicPrivateEnum} from "@interfaces/Enums";

export const getTaxRecordColumns = (
  propertyId: number,
  exitingRecordYears: number[],
  onUpdateTaxRecord: (action: "SAVE" | "UPDATE" | "DELETE", newTaxRecord: TaxRecordServer) => Promise<void> | void
) => {
  const taxRecordColumns: Column<TaxRecordServer>[] = [
    {
      id: 'year',
      title: 'Year',
      field: 'year'
    },
    {
      id: 'accountNumber',
      title: 'Account Number',
      field: 'accountNumber',
      render: (row) => row.accountNumber || 'Not Set'
    },
    {
      id: 'appraisedValue',
      title: 'Appraised Value',
      field: 'appraisedValue'
    },
    {
      id: 'taxRate',
      title: 'Tax Rate',
      field: 'taxRate'
    },
    {
      id: 'taxesOwed',
      title: 'Taxes Owed',
      field: 'taxesOwed'
    },

    {
      id: 'paymentDueDate',
      title: 'Payment Due Date',
      field: 'paymentDueDate',
      render: (row) => formatDate(row.paymentDueDate)
    },
    {
      id: 'action',
      title: 'Actions',
      field: 'actions',
      oneLine: true,
      actionBar: true,
      render: row =>
        <>
          <OverlayTrigger
            placement="top-end"
            overlay={<Tooltip id={`tt-edit-${row.id}`}>Edit</Tooltip>}
          >
            <Button size={"sm"} variant={"primary"} onClick={() => {
              openAddEditTaxRecordModal({
                propertyId: propertyId,
                id: row.id,
                exitingRecordYears: exitingRecordYears,
                onComplete: onUpdateTaxRecord
              })
            }}>
              <FaPencilAlt/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top-end"
            overlay={<Tooltip id={`tt-edit-${row.id}`}>Edit</Tooltip>}
          >
            <Button size={"sm"} variant={"danger"} onClick={() => {
              if (row.id && confirm("Are you sure?")) {
                const t = loadingTrigger();
                deleteTaxRecord(propertyId, row.id)
                  .then(() => onUpdateTaxRecord("DELETE", row))
                  .catch(alertApiErrors)
                  .finally(() => loadingRelease(t));
              }
            }}>
              <FaTrash/>
            </Button>
          </OverlayTrigger>
        </>
    }
  ]
  return taxRecordColumns;
}

export const taxRecordNotesColumns: Column<TaxRecordServer>[] = [
  {
    id: 'year',
    title: 'Year',
    field: 'year'
  },
  {
    id: 'note',
    title: 'Note',
    field: 'content'
  }
]

export const getDocumentsColumn = (propertyId: number, refreshList: () => void): Column<DocumentObject>[] => [
  {
    id: 'document',
    title: 'Document Name',
    field: 'freeFormTitle',
    filter: true,
    onEdit: async (newRowData) => {
      if (!newRowData.freeFormTitle) throw new Error('Document Name Required');
      const t = loadingTrigger();
      updateFreeFormTitle(propertyId, newRowData.id, {documentName: newRowData.freeFormTitle})
        .then(refreshList)
        .then(() => addAlert({
          type: 'success', content: 'Document name updated.'
        }))
        .catch(alertApiErrors)
        .finally(() => loadingRelease(t));
    }
  },
  {
    id: 'fileName',
    title: 'File Name',
    field: 'fileName',
    filter: true,
    onEdit: async (newData, oldData) => {
      const oldFileName = oldData.fileName;
      const newFileName = newData.fileName;
      if (!newFileName) throw new Error("File Name Required");
      if (fileNameCheckPrompt(newFileName, oldFileName!)) {
        const t = loadingTrigger();
        updateDocumentName(newData.id, newFileName)
          .then(refreshList)
          .then(() => addAlert({
            type: 'success', content: 'File name updated.'
          }))
          .catch(alertApiErrors)
          .finally(() => loadingRelease(t));
      }
    }
  },
  {
    id: 'fileType',
    title: 'File Type',
    field: 'fileType',
    filter: true
  },
  {
    id: 'internal',
    title: 'Public/Private',
    field: 'isInternal',
    lookup: PublicPrivateEnum,
    filter: true,
  },
  {
    id: 'action',
    title: 'Actions',
    field: 'actions',
    oneLine: true,
    actionBar: true,
    render: (documentObject) => {
      return (
        <>
          <OverlayTrigger
            placement="bottom-end"
            overlay={<Tooltip id={`tt-delete-${documentObject.id}`}>Delete</Tooltip>}>
            <Button
              variant={"danger"}
              name="deleteDocument"
              onClick={() => {
                if (confirm("Are you sure you want to delete this document?")) {
                  const t = loadingTrigger();
                  deletePropertyDocument(propertyId, documentObject.id)
                    .then(refreshList)
                    .catch(alertApiErrors)
                    .finally(() => loadingRelease(t));
                }
              }}
              className="btn btn-sm mr-2">
              <FaTrash/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom-end"
            overlay={<Tooltip
              id={`tt-internal-${documentObject.id}`}> Mark
              as {documentObject?.isInternal ? "Public" : "Internal"}</Tooltip>}>
            <Button
              size='sm'
              variant='success'
              onClick={() => {
                const t = loadingTrigger();
                toggleDocumentVisibility(propertyId, documentObject.id)
                  .then(refreshList)
                  .finally(() => loadingRelease(t));
              }}
            >
              {documentObject.isInternal ? <BiLogOutCircle/> : <BiLogInCircle/>}
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom-end"
            overlay={<Tooltip id={`tt-download-${documentObject.id}`}>Download</Tooltip>}
          >
            <a
              href={`${downloadDocumentUrl(documentObject.id)}?access_token=${getAccessToken()}`}
              onClick={(event) => {
                event.preventDefault();
                downloadFileByUrl(downloadDocumentUrl(documentObject.id), documentObject.fileName)
              }}
              className="btn btn-sm btn-success mr-2"
            >
              <FaDownload/>
            </a>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom-end"
            overlay={<Tooltip id={`tt-view-${documentObject.id}`}>View</Tooltip>}
          >
            <a
              href={openUrlWithToken(viewDocumentUrl(documentObject.id))}
              onClick={(event) => {
                event.preventDefault();
                openShowDocumentModal({
                  url: viewDocumentUrl(documentObject.id),
                  name: documentObject.fileName || 'file'
                })
              }}
              className="btn btn-sm btn-success mr-2"
            ><FaRegEye/>
            </a>
          </OverlayTrigger>
        </>
      )
    }
  }
]
