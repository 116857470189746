import axios from "axios";
import getUserApiPath from "@hornet-api/getUserApiPath";
import {RegisteredTrustedDevices} from "@legacy/views/UserProfileLegacy/TwoFaDevice/tableData";

const getTwoFaRegisteredAndTrustedDevices = async () => {
  const url = `${getUserApiPath()}/2fa/registered-trusted-devices`;
  return axios.get<RegisteredTrustedDevices>(url).then(res => res.data);
};

export default getTwoFaRegisteredAndTrustedDevices;
