/* eslint-disable no-console */
/* eslint-disable no-magic-numbers */
import {useCallback} from 'react';
import {useSetRecoilState} from 'recoil';
import authenticationBlobAtom from '@state/recoil/authentication/core/authenticationBlobAtom';
import * as Auth from '@server-api/index';
import {updateUserRoleGroups} from "@state/globalState/userRoleGroupsState";
import {getCleanBaseUri} from "@common/baseUri";

const useVerify2faMobileUser = () => {
	const setCurrentUser = useSetRecoilState(authenticationBlobAtom);

	return useCallback(async (otp2fa) => {
		try {
			const response = await Auth.verifyMobile2faUser(otp2fa);
			if (response.twoFactorAuth) {
				return response;
			}
			const active_user = response[0];
			await updateUserRoleGroups();
			console.info('Welcome user', active_user.sub);
			const newState = {
				active_user,
				impersonation_user: null,
			};
			setCurrentUser((state) => ({
				...state,
				...newState,
			}));
			window.requestAnimationFrame(() => {
				window.location = `${getCleanBaseUri()}/login/tokenToSessionAuth?access_token=${active_user.token}`;
			});
		} catch (oe) {
			return Promise.reject(oe);
		}
	}, [setCurrentUser]);
};

export default useVerify2faMobileUser;
