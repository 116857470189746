import axios from 'axios';
import DocumentRequest from '@interfaces/DocumentRequest';
import getUserApiPath from "@hornet-api/getUserApiPath";

const uploadDocument = (docRequest: DocumentRequest, file: File) => {
  const url = `${getUserApiPath()}/contact/document/documentRequest/completeDocumentRequest`;
  const formData = new FormData();

  formData.append("requestId", docRequest.requestCode);
  formData.append("document", file);
  formData.append("documentType", docRequest.documentType);
  return axios.post(url, formData)
}

export default uploadDocument;
