/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-bind */
import React, {useCallback, useMemo} from 'react';
import Pagination from 'react-bootstrap/Pagination';
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";

type PaginationProps = {
	currentPage: number,
	pageSize: number,
	numItems: number,
	totalItems: number,
	hidePagination?: boolean;
	onChange: (newPage: number) => void
}

const getPageArr = (maxPage: number, currentPage: number, delta = 2) => {
	const current = currentPage;
	const last = maxPage;
	const left = current - delta;
	const right = current + delta + 1;
	const range = [];
	const rangeWithDots = [];
	let l = 0;

	for (let i = 1; i <= last; i++) {
		if (i == 1 || i == last || i >= left && i < right) 
			range.push(i);
    
	}

	for (const i of range) {
		if (l) {
			if (i - l === 2) 
				rangeWithDots.push(l + 1);
			else if (i - l !== 1) 
				rangeWithDots.push('...');
		}
		rangeWithDots.push(i);
		l = i;
	}

	return rangeWithDots;
};

const PaginationComponent = (props: PaginationProps) => {
	const {currentPage, pageSize, numItems, totalItems, onChange} = props;
	
	const maxPage = useMemo(() => {
		return Math.ceil(numItems / pageSize);
	}, [numItems, pageSize]);

	const paginationArr = useMemo(() => {
		return getPageArr(maxPage, currentPage);
	}, [maxPage, currentPage]);

	const handleOnFirstPageClick = useCallback(() => {
		const page = Math.max(currentPage - 1, 1);
		if (typeof onChange === 'function') onChange(page);
	}, [onChange, currentPage]);

	const handleOnLastPageClick = useCallback(() => {
		const page = Math.min(currentPage + 1, maxPage);
		if (typeof onChange === 'function') onChange(page);
	}, [onChange, currentPage, maxPage]);

	const items = useMemo(() => {
		return paginationArr.map((val, index) => {
			if (val !== '...') {
				const page = val as number;
				const handlePageChangeClickHandler = () => {
					if (typeof onChange === 'function') onChange(page);
				};
				const active = page === currentPage;

				return (
					<Pagination.Item key={page} active={active} onClick={handlePageChangeClickHandler}>
						{page}
					</Pagination.Item>
				);
			}
			return <Pagination.Ellipsis key={`ellipse-${index}`}/>;
		});
	}, [paginationArr, onChange]);

	const renderCounts = useMemo(() => {
		const from = (currentPage - 1) * pageSize + 1;
		const to = Math.min(numItems, currentPage * pageSize);
		return numItems ?
			(
				<div className={`mr-3`}>
					{`${from}-${to} of ${numItems} ${numItems !== totalItems ? `from total ${totalItems}` : ''}`}
				</div>
			)
			: null;
	}, [currentPage, pageSize, numItems, totalItems])

	return (
		<div className={'footer-area border-top'}>
			{renderCounts}
			{!props.hidePagination ? (
			<Pagination>
				<Pagination.Item className={'line-height-1'} disabled={currentPage === 1} onClick={handleOnFirstPageClick}>
					<AiOutlineLeft/>
				</Pagination.Item>
				{items}
				<Pagination.Item className={'line-height-1'} disabled={currentPage === maxPage} onClick={handleOnLastPageClick}>
					<AiOutlineRight/>
				</Pagination.Item>
			</Pagination>
			) : null}
		</div>
	);
}


export default React.memo(PaginationComponent);
