import {
  associatedLoansState,
  companyPositionListState,
  contactAssociationsCountState,
  contactState,
  documentsState,
  investmentState,
  isLoadedCurrentTabDataState,
  lpaState,
  noteState
} from './globalState';
import getNotes from '@hornet-api/contact/getNotes';
import getContact from '@hornet-api/contact/getContact';
import getDocuments from '@hornet-api/contact/getDocuments';
import getInvestments from '@hornet-api/contact/getInvestments';
import getContactAssociatedLoansAdmin from "@hornet-api/contact/getContactAssociatedLoansAdmin";
import getContactAssociatedCount from "@hornet-api/contact/getContactAssociatedCount";
import {ContactAssociationCount} from "@interfaces/ContactAdmin";
import {getLPAs} from "@hornet-api/contact/getLPAs";


export const updateNotes = async (contactId: number) => {
  const notes = await getNotes(contactId);
  noteState.set(notes);
}

export const updateContact = async (contactId: number) => {
  const contact = await getContact(contactId);
  contactState.set(contact);
}

export const updateDocuments = async (contactId: number) => {
  const documents = await getDocuments(contactId);
  documentsState.set(documents);
}

export const updateInvestments = async (contactId: number) => {
  const investments = await getInvestments(contactId);
  investmentState.set(
    investments.map((investment) => ({
      ...investment,
      entityStr: investment.company?.name || investment.contacts?.map(({name}) => name).join(' ') || ''
    }))
  );
}

export const updateAssociatedLoans = async (contactId: number) => {
  const loans = await getContactAssociatedLoansAdmin(contactId);
  associatedLoansState.set(loans);
}

export const updateLPAs = async (contactId: number) => {
  const lPAs = await getLPAs(contactId);
  lpaState.set(lPAs);
}

export const updateContactAssociatedCount = async (contactId: number) => {
  const contactAssociationCount = await getContactAssociatedCount(contactId);
  contactAssociationsCountState.set(contactAssociationCount);
}

export const updateAll = async (contactId: number) => {
  await Promise.all([
    updateContact(contactId),
    updateInvestments(contactId),
    updateAssociatedLoans(contactId),
    updateContactAssociatedCount(contactId),
  ])
}

export const getContactPropeties = () => {

}

export const clearAll = () => {
  noteState.set(null);
  companyPositionListState.set(null);
  contactState.set(null);
  documentsState.set(null);
  investmentState.set(null);
  contactAssociationsCountState.set({} as ContactAssociationCount);
  associatedLoansState.set(null);
  lpaState.set(null);
  isLoadedCurrentTabDataState.set(false);
}