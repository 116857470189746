import React, {useEffect, useRef, useState} from 'react';
import hornetCapitalLogo from '@assets/images/hornet_capital_logo.svg';
import useVerify2faUser from "@legacy/views/auth/TwoFactorAuthView/useVerify2faUser";
import {getTwoFactorAuthConfig} from "@hornet-api/twoFactorAuth/meta/getTwoFactorAuthConfig";
import {loadingRelease, loadingTrigger} from "@components/LoadingOverlay";
import {alertApiErrors} from "@common/errors";
import {TwoFactorAuthAtLogin, TwoFactorAuthConfig} from "@interfaces/TwoFactorAuth";
import {SubmitHandler, useForm} from "react-hook-form";
import {TextBox} from "@components/forms/react-hook-form-bootstrap";
import {fetchUser2FA} from "@hornet-api/twoFactorAuth/meta/fetchUser2FA";
import LoadingWithWrapper from "@components/Indicators/LoadingWithWrapper";
import OtpResendWarning from "@legacy/views/auth/TwoFactorAuthView/common/OtpResendWarning";
import VerificationFailedWarning from "@legacy/views/auth/TwoFactorAuthView/common/VerificationFailedWarning";
import ResendOtpButton from "@legacy/views/auth/TwoFactorAuthView/common/ResendOtpButton";
import useLogoutHandler from "@legacy/views/auth/useLogoutHandler";
import resendOTP2FA from "@hornet-api/twoFactorAuth/meta/resendOTP2FA";
import {addAlert} from "@components/Alert";
import blueFooterImage from "@images/blue_footer_image.svg";
import MobileTwoFactorInformation from "@legacy/views/auth/TwoFactorAuthView/common/MobileTwoFactorInformation";
import getMobileTokenForWeb, {Mobile2FA} from "@hornet-api/twoFactorAuth/user/mobile/getMobileTokenForWeb";
import {Button, Col, Row} from "react-bootstrap";
import {TwoFaTypeEnum} from "@interfaces/GeneratedEnums";
import LoginSocket from "@legacy/views/auth/TwoFactorAuthView/common/LoginSocket";

interface Form2FA {
  otp2fa: string;
}

interface VerificationFailedResponse {
  message: string,
  twoFactorAuth: TwoFactorAuthAtLogin
}

const defaultForm2FAData: Form2FA = {
  otp2fa: ''
}

const TwoFactorAuthView = () => {
  const verify2faUser = useVerify2faUser();
  const [twoFactorAuthConfig, setTwoFactorAuthConfig] = useState<TwoFactorAuthConfig | null>();
  const [user2FA, setUser2FA] = useState<TwoFactorAuthAtLogin | null>();
  const [countdown, setCountdown] = useState<number>(0);
  const firstInputRef = useRef<HTMLInputElement>(null);
  const logoutHandler = useLogoutHandler();
  const [mobileApplication2FA, setMobileApplication2FA] = useState<Mobile2FA | null>();
  const [twoFaType, setTwoFaType] = useState<keyof typeof TwoFaTypeEnum>('OFF')

  const {
    handleSubmit,
    control,
    reset,
    getValues,
  } = useForm<Form2FA>({defaultValues: defaultForm2FAData});

  const resetLockedForSeconds = (countdown: number) => {
    setCountdown(countdown)
    if (!countdown && user2FA) {
      setUser2FA({
        ...user2FA,
        resendLockedForSeconds: 0
      })
    }
  }

  useEffect(() => {
    firstInputRef.current?.focus();
  }, [firstInputRef.current]);

  useEffect(() => {
    const t = loadingTrigger();
    Promise.all([
      getTwoFactorAuthConfig()
        .then((data) => {
          setTwoFactorAuthConfig(data)
        }),
      fetchUser2FA()
        .then((data) => {
          if (!data?.isEnabled) {
            logoutHandler();
            return;
          }
          setTwoFaType(data.isFirebaseEnabled ? 'APP_PUSH_NOTIFICATION' : 'SMS');
          setUser2FA(data)
        })
    ])
      .catch((error) => {
        if (error.response.status === 429) {
          logoutHandler()
          return;
        }
        alertApiErrors(error)
      })
      .finally(() => {
        loadingRelease(t)
      });
  }, []);

  useEffect(() => {
    twoFaType === 'APP_PUSH_NOTIFICATION' && getMobileTokenForWeb().then(setMobileApplication2FA)
  }, [twoFaType]);

  const resendOtp = () => {
    if (countdown > 0) return;

    const t = loadingTrigger();
    resendOTP2FA()
      .then(async (twoFactorAuth) => {
        if (twoFactorAuth) {
          await setUser2FA(twoFactorAuth)
        }
        reset(getValues());
      })
      .catch((error) => {
        if (error.response.status === 429) {
          logoutHandler();
          return;
        }
        alertApiErrors(error);
      })
      .finally(() => {
        loadingRelease(t);
      });
  }

  const onSubmit: SubmitHandler<Form2FA> = (data) => {
    const t = loadingTrigger();
    verify2faUser(data.otp2fa).then((response: unknown) => {
      if (response) {
        const data = response as VerificationFailedResponse
        setUser2FA(data.twoFactorAuth);
        addAlert({
          type: "danger",
          content: data.message,
        });
      }
    }).catch((error) => {
      if (error?.response?.status === 429) {
        addAlert({
          type: "danger",
          content: error.response.data
        })
        logoutHandler()
        return;
      }
      alertApiErrors(error)
    }).finally(() => loadingRelease(t));
  }

  if (!twoFactorAuthConfig) {
    return null;
  }

  return (
    <div className="login-wrapper login-container">
      <div className="container login-area">
        <div className="login-logo">
          <img src={hornetCapitalLogo} alt="Hornet Capital"/>
        </div>
        <div className="card panel-custom">
          <div className="card-heading custom_head">
            <div className="view-header">
              <div className="header-title">
                <h3>Login</h3>
                <small> {user2FA?.isFirebaseEnabled ? 'Approve Sign In Request' : 'Please enter the Authorization Code you received on your phone'}</small>
              </div>
            </div>
          </div>

          <div className="card-body">
            {
              (!twoFactorAuthConfig || !user2FA) && <LoadingWithWrapper/>
            }
            {
              twoFactorAuthConfig && user2FA && user2FA.isEnabled && (
                twoFaType === 'APP_PUSH_NOTIFICATION' && mobileApplication2FA ? (
                  <>
                    <MobileTwoFactorInformation textCode={mobileApplication2FA.otp}/>
                    <Row>
                      <Col>
                        <Button
                          variant={'secondary'}
                          name="Cancel"
                          onClick={logoutHandler}
                          className={'pull-right'}
                        >Cancel </Button>
                      </Col>
                    </Row>
                    {mobileApplication2FA.uniqueName && <LoginSocket uniqueName={mobileApplication2FA.uniqueName}/>}
                  </>
                ) : (
                  <form noValidate method="post" id="loginForm" onSubmit={handleSubmit(onSubmit)}>
                    <OtpResendWarning
                      maxResendsPerLock={twoFactorAuthConfig?.maxResendsPerLock}
                      resendLockHours={twoFactorAuthConfig?.resendLockHours}
                      resendCount={user2FA?.resendCount}
                    />
                    <TextBox
                      name={'otp2fa'}
                      label={'Please enter your Authorization Code'}
                      control={control}
                      type={"tel"}
                      ref={firstInputRef}
                      maxLength={twoFactorAuthConfig.codeLength}
                      rules={{
                        required: true,
                        minLength: {
                          value: twoFactorAuthConfig.codeLength,
                          message: `It should be a ${twoFactorAuthConfig.codeLength} digit number`
                        },
                        maxLength: {
                          value: twoFactorAuthConfig.codeLength,
                          message: `It should be a ${twoFactorAuthConfig.codeLength} digit number`
                        }
                      }}
                    />

                    <ResendOtpButton
                      onCountdownChange={resetLockedForSeconds}
                      resendLockedForSeconds={user2FA?.resendLockedForSeconds}
                      resendOtp={resendOtp}
                    />

                    <VerificationFailedWarning
                      failureCount={user2FA?.failureCount}
                      maxFailureCount={twoFactorAuthConfig?.maxFailureCount}
                    />

                    <div className="text-center">
                      <button
                        type="submit"
                        name="Verify"
                        className="btn btn-warning btn-block font-weight-bold"
                      >Verify
                      </button>
                      <div>or</div>
                      <button
                        type="button"
                        name="Cancel"
                        onClick={logoutHandler}
                        className="btn btn-secondary btn-block"
                      >Cancel
                      </button>
                    </div>
                  </form>
                )
              )
            }
          </div>
        </div>
      </div>
      <div className="footer" style={{backgroundImage: `url( ${blueFooterImage})`}}></div>
    </div>
  );
};

export default TwoFactorAuthView;
