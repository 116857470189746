import React, {useCallback, useEffect, useState} from "react";
import {DocumentTypeEnum} from "@interfaces/GeneratedEnums";
import {DocumentObject} from "@interfaces/DocumentRequest";
import {Button, Card} from "react-bootstrap";
import {openDocumentUploadModal} from "@admin-ui/components/DocumentUploadModal";
import {FaPlus} from "react-icons/fa";
import {DocumentUpload} from "@interfaces/Document";
import {convertDocumentToFormData} from "@admin-ui/pages/PropertyPage/contant";
import {addAlert} from "@components/Alert";
import {addDocument, fetchDocumentsByPropertyId} from "@hornet-api/properties/document";
import {alertApiErrors} from "@common/errors";
import BootstrapSmartTable from "@components/BootstrapSmartTable";
import {getDocumentsColumn} from "@admin-ui/pages/PropertyPage/show/tableData";

interface Props {
  propertyId: number
}

const {FREEFORM} = DocumentTypeEnum
const PropertyDocuments = ({propertyId}: Props) => {

  const [documents, setDocuments] = useState<DocumentObject[]>([])

  const fetchDocuments = useCallback(() => {
    fetchDocumentsByPropertyId(propertyId)
      .then(setDocuments)
      .catch(alertApiErrors)
  }, [propertyId]);

  useEffect(fetchDocuments, [propertyId]);

  const handleDocumentSubmit = async (requestData: DocumentUpload) => {
    await addDocument(propertyId, convertDocumentToFormData(requestData)).then(() => {
      fetchDocuments();
      addAlert({
        type: "success",
        content: "Document uploaded successfully."
      });
    });
  };

  return (
    <Card>
      <Card.Header>Documents
        <Button
          variant={"success"}
          className="pull-right"
          onClick={() => {
            openDocumentUploadModal({
              documentType: FREEFORM,
              onSubmit: handleDocumentSubmit
            })
          }}>
          <FaPlus/> Add Documents
        </Button>
      </Card.Header>
      <Card.Body>
        <BootstrapSmartTable
          name='propertyDocumentPage'
          columns={getDocumentsColumn(propertyId, fetchDocuments)}
          data={documents}
        />
      </Card.Body>
    </Card>


  );
}
export default PropertyDocuments;